export const environment = {
  production: false,
  hmr: false,
  debug: false,
  client: 'NCI',
  hostName: 'staging.insight.nissanleads.ca',
  environmentName: 'staging',
  appName: 'NCI Insight ',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: 'f2d4e4f2-e9f1-47b9-891f-d7b4f6eaf1f1'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#414141', '#ababab', '#000', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://nci-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://nci-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://nci-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/nci/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['DigitalRetailingOverview']
};
